@require "bootstrap/variables";
@require "bootstrap/variable_overrides";
@require "core/variables";
@require "bootstrap/normalize";
@require "core/fonts"
* {
  box-sizing: border-box
}

*:before, *:after {
  box-sizing: border-box
}

html {
  -ms-overflow-style: scrollbar;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  font-family: "interface", sans-serif !important;
  font-size: 14px !important;
  color: $default-text-color !important;
  .body-sub {
	min-height: 100vh;
	overflow: hidden;
  }
}
