@font-face {
  font-family: 'interface';
  src: url('../../fonts/IF_W_Rg.eot');
  src: url('../../fonts/IF_W_Rg.eot?#iefix') format('embedded-opentype'),
		  url('../../fonts/IF_W_Rg.woff') format('woff'),
		  url('../../fonts/IF_W_Rg.woff2') format('woff2'),
		  url('../../fonts/IF_W_Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'interface';
  src: url('../../fonts/IF_W_Bd.eot');
  src: url('../../fonts/IF_W_Bd.eot?#iefix') format('embedded-opentype'),
		  url('../../fonts/IF_W_Bd.woff') format('woff'),
		  url('../../fonts/IF_W_Bd.woff2') format('woff2'),
		  url('../../fonts/IF_W_Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
